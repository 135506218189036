<template>
  <div class="mt-5">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <v-form v-model="valid" ref="stepForm">
      <v-tabs v-model="selectedDay" centered class="mb-5" @change="dayChanged">
        <v-tabs-slider color="secondary"></v-tabs-slider>

        <v-tab v-for="(day, i) in dayValueCopy" :key="i">
          Day {{ i + 1 }}
        </v-tab>
      </v-tabs>

      <!-- days -->
      <v-tabs-items v-model="selectedDay">
        <v-tab-item eager v-for="(day, i) in dayValueCopy" :key="i">
          <div>
            <div class="py-3 d-flex align-center justify-start">
              <!-- steps -->
              <div
                class="secondary align-center d-flex justify-center mr-3 cursor-pointer"
                style="width: 30px; height: 30px; border-radius: 50%"
                v-for="(mapStep, j) in day.obj.schedules"
                :key="j"
                :class="day.selectedMapStepIndex == j ? `map-step-active` : ''"
                @click="MapStepView(mapStep, j, i)"
              >
                <span class="white--text font-13 font-500">{{ j + 1 }}</span>
              </div>

              <!-- add new step -->
              <div
                class="secondary align-center d-flex justify-center mx-2 cursor-pointer"
                style="width: 30px; height: 30px; border-radius: 50%"
                @click="newMapStepHandler(i)"
              >
                <span class="white--text font-13 font-500"
                  ><v-icon color="white" size="18">mdi-plus</v-icon></span
                >
              </div>
            </div>
            <div v-if="day.selectedMapStep">
              <v-row class="pt-3">
                <!-- Transportation -->
                <v-col cols="12" md="12" class="pt-1 pb-0">
                  <label class="grey--text font-12 font-500"
                    >Transportation</label
                  >
                  <base-select
                    :items="transportList"
                    v-model="day.selectedMapStep.transport"
                    placeholder="Select transportation"
                  ></base-select>
                </v-col>

                <!-- activity Attractions -->
                <v-col cols="12" class="pt-1 pb-0">
                  <label class="grey--text font-12 font-500">Attraction</label>
                  <v-menu
                    class="search-menu"
                    offset-y
                    :content-class="
                      attractionSearch
                        ? 'search-menu-border--active'
                        : 'search-menu-border'
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="search-field mr-3">
                        <v-text-field
                          outlined
                          placeholder="Add a short, clear description"
                          prepend-inner-icon="mdi-magnify"
                          hide-details="true"
                          height="35"
                          dense
                          :value="
                            day.selectedMapStep.attraction &&
                            day.selectedMapStep.attraction.name
                              ? day.selectedMapStep.attraction.name[language]
                              : day.selectedMapStep.attraction
                          "
                          @input="searchAttraction"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </div>
                    </template>
                    <v-list v-if="attractions.length > 0">
                      <v-list-item
                        class="cursor-pointer"
                        @click="selectAttraction(item, i)"
                        v-for="(item, index) in attractions"
                        :key="index"
                      >
                        <v-list-item-title>{{
                          item.name || item.title
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <div
                      class="white py-1 px-5"
                      v-if="attractionSearch && attractions.length == 0"
                    >
                      <span>No Results</span>
                    </div>
                  </v-menu>
                </v-col>

                <!-- description -->
                <v-col cols="12" md="12" class="pt-1 pb-0 mt-3">
                  <label class="grey--text font-12 font-500">DESCRIPTION</label>
                  <base-textarea
                    sizeWord="250"
                    placeholder="What is this step about? Tell us more juicy details."
                    v-model="day.selectedMapStep.description[language]"
                  ></base-textarea>
                </v-col>
                <!-- price -->
                <v-col cols="12" md="12" class="pt-1 pb-0 mt-3">
                  <label class="grey--text font-12 font-500"
                    >Experience Price</label
                  >
                  <base-input
                    type="number"
                    placeholder="Price"
                    :rules="[(v) => (!!v && v > 0) || '']"
                    v-model="day.selectedMapStep.price"
                  ></base-input>
                </v-col>
              </v-row>
            </div>
            <div
              class="pb-3 cursor-pointer d-flex align-center justify-space-between mt-3"
              v-if="
                day.obj && day.obj.schedules && day.obj.schedules.length > 0
              "
            >
              <v-checkbox
                v-model="day.selectedMapStep.guided"
                :hide-details="true"
                :label="
                  'This step is ' + day.selectedMapStep.guided
                    ? 'guided'
                    : 'unguided'
                "
              ></v-checkbox>
              <div @click="deleteMapStep(i)">
                <v-icon color="error">mdi-delete</v-icon>
                <span class="error--text">Delete this step</span>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-col cols="12">
        <div class="b-bottom my-1"></div>
      </v-col>

      <!-- **** Stats **** -->
      <v-col cols="12" class="mb-0 pt-0 pb-0">
        <span class="font-26 font-500 d-block">Good to know</span>
      </v-col>

      <!-- what to tabs -->
      <v-col cols="12" class="px-6">
        <div class="mt-4" v-for="(whatTo, i) in goodToKnow" :key="i">
          <span class="grey--text font-12 font-500 d-block mb-2">{{
            whatTo.title
          }}</span>
          <v-row v-for="(desc, j) in whatTo.desc" :key="j">
            <!-- delete -->
            <v-col cols="1" class="py-1">
              <div class="base-btn" @click="removeWhatToHandler(i, j)">
                <v-icon color="error">mdi-minus-circle-outline</v-icon>
              </div>
            </v-col>

            <!-- text -->
            <v-col cols="11" class="py-1">
              <base-input
                placeholder="Add a short headline"
                v-model="desc.value[language]"
              ></base-input>
            </v-col>
          </v-row>

          <!-- add new -->
          <div class="py-1 mt-2">
            <div class="base-btn" @click="addWhatToHandler(i)">
              <v-icon color="grey">mdi-plus-circle-outline</v-icon>
            </div>
          </div>
        </div>
      </v-col>
    </v-form>

    <div class="mt-14">
      <buttons-experience
        :isValid="valid"
        :key="valid"
        @cancel="$emit('backHandler')"
        @next="nextHandler"
        cancelText="Back"
      ></buttons-experience>
    </div>

    <!-- preview image -->
    <v-dialog
      v-model="imagePreview"
      width="600"
      content-class="pa-10 no-shadow"
    >
      <div class="preview-dialog">
        <v-icon
          @click="imagePreview = false"
          class="close-preview cursor-pointer"
          color="white"
          >mdi-close</v-icon
        >

        <img
          width="100%"
          height="400px"
          class="object-cover"
          :src="imagePreviewUrl"
          alt=""
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    },
    dayValue: {
      type: Array,
      default: () => {}
    },
    daysChanged: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience
    // StepSix,
  },
  data: () => ({
    dayValueCopy: [],
    name: "",
    valid: false,
    goodToKnow: [
      { title: "What To Bring", desc: [{ value: {} }] },
      { title: "What To Wear", desc: [{ value: {} }] },
      { title: "What To Expect", desc: [{ value: {} }] }
    ],
    obj: { schedules: [] },

    stepMapLink: "",
    transportList: [
      { title: "Walking", value: "walk" },
      { title: "Drive", value: "drive" },
      { title: "Swim", value: "swim" },
      { title: "Sleep", value: "sleep" },
      { title: "Fly", value: "fly" },
      { title: "Eat", value: "eat" }
    ],

    filesUploaded: false,
    keyMarkChange: false,
    keyChanged: false,

    imagePreview: false,
    imagePreviewUrl: "",
    selectedDay: "",
    attractions: [],

    selectedDayIndex: 0,
    attractionSearch: "",
    language: "en"
  }),
  methods: {
    dayChanged(val) {
      this.dayValueCopy[val].selectedMapStepIndex =
        this.dayValueCopy[val].obj.schedules.length - 1;
      this.dayValueCopy[val].selectedMapStep =
        this.dayValueCopy[val].obj.schedules[
          this.dayValueCopy[val].obj.schedules.length - 1
        ];

      this.selectedDayIndex = val;
    },
    // map steps
    newMapStepHandler(i) {
      this.dayValueCopy[i].obj.schedules.push({
        guided: false
      });
      this.dayValueCopy[i].selectedMapStepIndex =
        this.dayValueCopy[i].obj.schedules.length - 1;
      this.dayValueCopy[i].selectedMapStep =
        this.dayValueCopy[i].obj.schedules[
          this.dayValueCopy[i].obj.schedules.length - 1
        ];
    },
    deleteMapStep(i) {
      this.dayValueCopy[i].obj.schedules = [
        ...this.dayValueCopy[i].obj.schedules.filter(
          (step, idx) => idx != this.dayValueCopy[i].selectedMapStepIndex
        )
      ];
      this.dayValueCopy[i].selectedMapStepIndex = 0;
      if (this.dayValueCopy[i].obj.schedules.length == 0) {
        this.dayValueCopy[i].selectedMapStep = { images: [] };
      } else {
        this.dayValueCopy[i].selectedMapStep =
          this.dayValueCopy[i].obj.schedules[0];
      }
    },
    MapStepView(mapStep, j, i) {
      this.dayValueCopy[i].selectedMapStep = mapStep;
      this.dayValueCopy[i].selectedMapStepIndex = j;
    },
    addWhatToHandler(i) {
      this.goodToKnow[i].desc.push({value: {}});
    },
    removeWhatToHandler(i, j) {
      this.goodToKnow[i].desc = [
        ...this.goodToKnow[i].desc.filter((_, idx) => idx != j)
      ];
    },
    async nextHandler() {
      this.$refs.stepForm.validate();
      let isValid = true;
      this.dayValueCopy.forEach((obj) => {
        obj.obj.schedules.forEach((sch) => {
          if (!sch.attraction || !sch.transport) {
            isValid = false;
          }
        });
      });
      if (!isValid) {
        setTimeout(() => {
          document.querySelector(".v-input.error--text:first-of-type");
        }, 100);
        this.$store.dispatch("showSnack", {
          text: "please check all fields",
          color: `error`
        });
        return;
      }

      this.dayValueCopy.forEach((obj) => {
        obj.obj.schedules.forEach((sch) => {
          if (sch.attraction._id) {
            sch.attraction = sch.attraction._id;
          }
          if (sch.transport.value) {
            sch.transport = sch.transport.value;
          }
        });
      });
      this.obj.bring = this.goodToKnow[0].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.wear = this.goodToKnow[1].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.expect = this.goodToKnow[2].desc.map((val) => {
        return { label: val.value };
      });

      let scheduleArr = [];
      this.dayValueCopy.forEach((day, idx) => {
        scheduleArr.push({
          day: {
            dayNumber: idx + 1,
            schedule: [...(day.obj?.schedules || [])]
          }
        });
      });
      this.$emit("nextHandler", {
        schedules: scheduleArr,
        bring: this.obj.bring,
        wear: this.obj.wear,
        expect: this.obj.expect
      });
    },

    fetchItemByID() {
      // this.obj = this.objItem;
      this.goodToKnow[0].desc = this.objItem.bring.map((val) => {
        return { value: val.label, _id: val._d };
      });
      this.goodToKnow[1].desc = this.objItem.wear.map((val) => {
        return { value: val.label, _id: val._d };
      });
      this.goodToKnow[2].desc = this.objItem.expect.map((val) => {
        return { value: val.label, _id: val._d };
      });

      // set schedules
      if (this.objItem.duration == 1) {
        this.dayValueCopy = [];
        this.objItem.schedules.forEach((day, i) => {
          if (i == 0) {
            this.dayValueCopy.push({
              obj: {
                schedules: day.day.schedule
              },
              selectedMapStep: { startTimeDay: "AM", description: {} },
              selectedMapStepIndex: 0
            });
          }
        });
      } else if (
        this.objItem.schedules.length > 0 &&
        this.dayValueCopy.length == this.objItem.duration &&
        this.dayValueCopy.length == this.objItem.schedules.length &&
        !this.daysChanged
      ) {
        this.dayValueCopy = [];
        this.objItem.schedules.forEach((day) => {
          this.dayValueCopy.push({
            obj: {
              schedules: day.day.schedule
            },
            selectedMapStep: { startTimeDay: "AM", description: {} },
            selectedMapStepIndex: 0
          });
        });
        // if day duration number < newSechule days
      } else if (this.dayValueCopy.length < this.objItem.schedules.length) {
        let newDayValue = [];
        this.objItem.schedules.forEach((day, i) => {
          if (i < this.dayValue.length) {
            newDayValue.push({
              obj: {
                schedules: day.day.schedule
              },
              selectedMapStep: { startTimeDay: "AM", description: {} },
              selectedMapStepIndex: 0
            });
          }
        });
        this.dayValueCopy = newDayValue;
      } else {
        let newDayValue = [];
        this.objItem.schedules.forEach((day) => {
          newDayValue.push({
            obj: {
              schedules: day.day.schedule
            },
            selectedMapStep: { startTimeDay: "AM", description: {} },
            selectedMapStepIndex: 0
          });
        });
        this.dayValueCopy.forEach((_, i) => {
          if (i > this.objItem.schedules.length - 1) {
            newDayValue.push({
              obj: {
                schedules: [{ guided: false, image: [] }]
              },
              selectedMapStep: {description: {}},
              selectedMapStepIndex: 0
            });
          }
        });
        this.dayValueCopy = newDayValue;
      }
    },
    async searchAttraction(event) {
      this.attractionUpdated = !this.attractionUpdated;
      if (event.length > 1) {
        let { data } = await this.$axios.get(
          `miniguide/all/new/search?searchWord=${event}`
        );
        if (data) {
          this.attractions = data.data;
        }
      } else {
        await this.fetchAttractions();
      }
    },
    async fetchAttractions() {
      let { data } = await this.$axios.get(
        "attraction/all/dropdown?pageNumber=1&pageSize=20"
      );
      if (data) {
        this.attractions = data.data;
      }
    },
    selectAttraction(item, dayIdx) {
      this.dayValueCopy[dayIdx].selectedMapStep.attractionText = item.name;
      this.dayValueCopy[dayIdx].selectedMapStep.attraction = item;
    }
  },
  async created() {
    this.language = this.$root.language
    if (this.objItem && this.objItem._id) {
      await this.fetchItemByID();
    } else {
      this.dayValueCopy = this.dayValue.map(() => {
        return {
          obj: {
            schedules: [{ guided: false, description: {} }]
          },
          selectedMapStep: {description: {}},
          selectedMapStepIndex: 0
        };
      });
      this.dayValueCopy[0].selectedMapStep =
        this.dayValueCopy[0].obj.schedules[0];
    }
    await this.fetchAttractions();
  }
};
</script>
