<template>
  <div class="details-steps">
    <div class="mb-5">
      <GmapMap
        v-if="item.data.location"
        :center="{
          lat: Number(item.data.schedules[0].day.schedule[0].latitude),
          lng: Number(item.data.schedules[0].day.schedule[0].longitude),
        }"
        :zoom="10"
        style="width: 100%; height: 300px"
      >
        <GmapMarker
          :key="index"
          v-for="(marker, index) in item.data.schedules[0].day.schedule"
          :position="{ lat: Number(marker.latitude), lng: Number(marker.longitude) }"
        />
      </GmapMap>
    </div>
    <div class="steps">
      <div class="step-line"></div>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="(day, i) in item.data.schedules"
          :key="i"
        >
          <div class="mb-8">
            <div v-for="(schedule, j) in day.day.schedule" :key="j">
              <div class="w-100">
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center justify-space-between">
                    <div class="step-min b-cover px-2 py-1 radius-20 mr-2">
                      <v-icon color="secondary"
                        >mdi-{{ transportIcon(schedule.transport) }}</v-icon
                      >
                    </div>
                    <span class="secondary--text font-12 font-500">{{
                      schedule.location
                    }}</span>
                  </div>
                </div>

                <div class="pl-7 mt-2">
                  <span class="font-20 font-500 d-block black--text">{{
                    schedule.title[$root.language]
                  }}</span>
                  <p class="font-12 white-grey--text">
                    {{ schedule.description[$root.language] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    images: [],
  }),
  methods: {
    transportIcon(transport) {
      if (transport == "drive") {
        return "car";
      }
      if (transport == "walk") {
        return "walk";
      }
      if (transport == "swim") {
        return "swim";
      }
      if (transport == "eat") {
        return "walk";
      } else {
        return "car";
      }
    },
  },
};
</script>

<style lang="scss">
.details-steps {
  .steps {
    position: relative;

    .step-line {
      content: "";
      position: absolute;
      left: 15px;
      top: 10px;
      height: calc(100% - 130px);
      width: 2px;
      background: $grayicon;
    }

    .step-min {
      position: relative;
      z-index: 9;
      background: white;
    }
  }
}
</style>
