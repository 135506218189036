<template>
  <div class="custom-experiences">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          :listItems="listItems"
          @paginationHandler="fetchList"
          :total="total"
          :loading="loading"
          @searchCustomExperience="searchCustomExperience"
          @reloadData="reloadData"
          @addCustomExperience="addCustomExperience"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          :key="reloadUI"
          v-if="item && item.data && item.data._id"
          :item="item"
          @editCustomExperience="editCustomExperience"
          @reloadData="reloadData"
          @collapse="collapse"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="addCustomExperienceDialog" max-width="750px">
      <new-custom-experience-dialog
        :key="addCustomExperienceDialog"
        @close="addCustomExperienceDialog = false"
        @reloadData="reloadData"
      ></new-custom-experience-dialog>
    </v-dialog>
    <v-dialog v-model="editCustomExperienceDialog" max-width="750px">
      <new-custom-experience-dialog
        v-if="item"
        :key="editCustomExperienceDialog"
        @close="editCustomExperienceDialog = false"
        @reloadData="reloadData"
        :itemID="item.data._id"
      ></new-custom-experience-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/custom-activity/ListItems";
import ItemBody from "@/components/custom-activity/ItemBody";
import NewCustomExperienceDialog from "@/components/dialogs/CustomExperiences/Index.vue"
export default {
  components: {
    ListItems,
    ItemBody,
    NewCustomExperienceDialog
  },
  data: () => ({
    page: 1,
    paramItem: { newPricing: {} },
    paramID: "",
    listItems: [],
    total: 0,
    item: { data: {} },
    loading: true,
    searchWord: undefined,
    editCustomExperienceDialog: false,
    addCustomExperienceDialog: false,
    reloadUI: false
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    async reloadData() {
      await this.fetchList(this.page);
      await this.fetchItemByID();
    },
    addCustomExperience() {
      this.addCustomExperienceDialog = true;
    },
    editCustomExperience() {
      this.editCustomExperienceDialog = true;
    },
    async searchCustomExperience(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.item = undefined;
        }
      }
    },
    async fetchList(page) {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      if (!page) {
        page = 1;
      }
      this.page = page;
      let { data } = await this.$axios.get(
        `custom-activity?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }pageSize=12&pageNumber=${page}&sort=2`
      );
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(
          `/custom-activity/${this.paramID}`
        );
        if (data) {
          this.item = data;
          this.reloadUI = !this.reloadUI;
        }
      }
    },
    collapse() {
      this.$router.push("/custom-experiences");
      this.paramID = undefined;
    },
    itemClicked(item) {
      this.$router.push(`/custom-experiences/${item._id}`);
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.custom-experiences {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
