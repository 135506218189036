<template>
  <div class="details-text">
    <div class="b-bottom pt-4">
      <!-- text details -->
      <div class="main-title d-flex align-center justify-space-between">
        <div>
          <span
            class="main-title d-block my-2 black--text transition-3s font-20 font-500"
            >{{ item.name[$root.language] }}</span
          >
          <span
            class="main-title d-block my-2 black-grey--text transition-3s font-14 font-500"
            v-if="item.location"
            >{{ item.location.address }}</span
          >
        </div>
        <div class="d-flex align-center">
          <div
            v-if="actions.includes('delete')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="deleteConfirm"
          >
            <v-icon color="error">mdi-delete</v-icon>
          </div>
          <div
            v-if="actions.includes('edit')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="$emit('editHandler')"
          >
            <v-icon color="black-grey">mdi-pencil</v-icon>
          </div>
        </div>
      </div>
      <p class="black--text font-15 mt-3">
        {{ item.title[$root.language] }}
      </p>
      <div class="d-flex align-center" v-if="item.price">
        <span class="d-block font-15 font-500 mt-2 mb-1 black--text"
          >Price:
          <span :class="item.jedoCommision ? 'text-commision' : ''">
            {{ item.price }}$</span
          ></span
        >
        <span
          class="d-block font-16 font-500 mt-2 mb-1 error--text mx-2"
          v-if="item.jedoCommision"
          >{{ item.price - item.jedoCommision }}$</span
        >
      </div>
      <p class="gray--text font-14">
        {{ item.description[$root.language] }}
      </p>
    </div>
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Array,
      default: () => ["hide", "delete", "edit"]
    }
  },
  data: () => ({
    deleteDialog: false,
    experienceDelete: { Experiences: "delete" },
    experienceEdit: { Experiences: "edit" },
    deleteEnable: false,
    editEnable: false
  }),
  methods: {
    deleteConfirm() {
      this.deleteDialog = true;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/custom-activity/${this.item._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    }
  },
  async created() {
    this.deleteEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceDelete
    );
    this.editEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceEdit
    );
  }
};
</script>

<style></style>
