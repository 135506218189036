<template>
  <div class="details-images">
    <div
      class="photos-section b-bottom pb-4 mt-4"
      v-if="item.data.images.length > 0"
    >
      <span class="font-15 black--text opacity-8 d-block mb-3"
        >Activities ({{ item.data.images.length }})</span
      >
      <v-row>
        <v-col cols="6">
          <div class="photo mb-2">
            <img
              v-if="item.data.images[0].mediaId"
              :src="item.data.images[0].mediaId.url"
            />
          </div>
        </v-col>

        <v-col cols="6">
          <div class="photo mb-2">
            <div
              class="photo-count cursor-pointer"
              @click="photosDialogHandler"
            >
              <span class="white--text font-26"
                >{{ item.data.images.length - 2 }}+</span
              >
            </div>
            <img
              v-if="item.data.images[1].mediaId"
              :src="item.data.images[1].mediaId.url"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :images="item.data.images"
        type="experience"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogPhotos,
  },
  data: () => ({
    photosDialog: false,
  }),
  methods: {
    photosDialogHandler() {
      this.photosDialog = true;
    },
  },
};
</script>

<style></style>
