<template>
  <div class="white pa-5 py-3">
    <main-section
      v-if="step == 1"
      key="1"
      @nextHandler="stepOneHandler"
      :objItem="obj"
      @dayValue="dayValueHandler"
      @backHandler="$emit('close')"
    ></main-section>
    <steps-section
      v-if="step == 2"
      key="2"
      @nextHandler="stepTwoHandler"
      :objItem="obj"
      :dayValue="dayValue"
      :daysChanged="daysChanged"
      @backHandler="step = 1"
    ></steps-section>
    <price-section
      v-if="step == 3"
      key="3"
      @backHandler="step = 2"
      @nextHandler="submitHandler"
      :objItem="obj"
    ></price-section>
  </div>
</template>

<script>
import MainSection from "./components/Main";
import StepsSection from "./components/Steps";
import PriceSection from "./components/TotalPrice";
export default {
  components: {
    MainSection,
    StepsSection,
    PriceSection
  },
  props: {
    itemID: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    step: 0,
    obj: undefined,
    dayValue: {},
    coverImage: {},
    newCoverImage: "",
    images: [],
    request: "",
    daysChanged: false
  }),
  watch: {
    $route: {
      handler() {
        this.step = 1;
      }
    }
  },
  methods: {
    stepOneHandler(val) {
      this.step = 2;
      this.obj = { ...val.obj };
      this.images = val.images;
      this.coverImage = val.coverImage;
      this.newCoverImage = val.obj.newCoverImage;
    },
    stepTwoHandler(val) {
      this.obj = { ...this.obj, ...val };
      this.step = 3;
    },
    async submitHandler(val) {
      this.obj.jedoCommision = +val;
      if (!this.itemID) {
        if (this.request) {
          this.obj.request = this.request;
        }
        let resCreate = await this.$axios.post(`custom-activity`, this.obj);

        if (resCreate.data) {
          // send cover image
          let formDataMedia = new FormData();
          formDataMedia.append("activityId", resCreate.data.data._id);
          formDataMedia.append("coverImage", this.coverImage);
          // request to upload media
          await this.$axios.post(`custom-activity/media/cover`, formDataMedia, {
            "content-type": "multipart/form-data"
          });

          if (this.images.length > 0) {
            const callback_Original = () => {
              return new Promise((resolve) => {
                this.images.forEach(async (img, i) => {
                  let formDataMedia = new FormData();
                  formDataMedia.append("activityId", resCreate.data.data._id);
                  formDataMedia.append("media", img.file);
                  // request to upload media

                  await this.$axios.post(
                    `custom-activity/media/images`,
                    formDataMedia,
                    {
                      "content-type": "multipart/form-data"
                    }
                  );

                  if (this.images.length - 1 == i) {
                    resolve();
                  }
                });
              });
            };
            callback_Original().then(async () => {
              this.$store.dispatch("showSnack", {
                text: "Custom experience added succesfully",
                color: `success`
              });
              this.$emit("reloadData");
              this.$emit("close");
            });
          } else {
            this.$emit("reloadData");
            this.$emit("close");
          }
        }
      } else {
        this.obj.images = this.images;
        if (this.newCoverImage) {
          // send cover image

          let formDataMedia = new FormData();
          formDataMedia.append("activityId", this.itemID);
          formDataMedia.append("coverImage", this.coverImage);
          // request to upload media

          await this.$axios.post(`custom-activity/media/cover`, formDataMedia, {
            "content-type": "multipart/form-data"
          });
        }

        const callback_Original = () => {
          return new Promise((resolve) => {
            this.images.forEach(async (img, i) => {
              if (!img._id) {
                let formDataMedia = new FormData();
                formDataMedia.append("activityId", this.itemID);
                formDataMedia.append("media", img.file);
                // request to upload media

                let res = await this.$axios.post(
                  `custom-activity/media/images`,
                  formDataMedia,
                  {
                    "content-type": "multipart/form-data"
                  }
                );

                if (res.data.data) {
                  this.obj = res.data.data;
                }
              }
              if (this.images.length - 1 == i) {
                resolve();
              }
            });
          });
        };
        callback_Original().then(async () => {
          // request to send infos to activity
          let resPut = await this.$axios.put(
            `custom-activity/${this.itemID}`,
            this.obj
          );
          if (resPut.data) {
            this.$store.dispatch("showSnack", {
              text: "edited succesfully",
              color: `success`
            });
            this.$emit("reloadData");
            this.$emit("close");
          }
        });
      }
    },
    dayValueHandler(val) {
      this.dayValue = [];
      if (val > 1 && val != this.daysDuration) {
        this.daysChanged = true;
      }
      for (let index = 0; index < val; index++) {
        this.dayValue.push({});
      }
    },
    async fetchItemById() {
      if (this.itemID) {
        let { data } = await this.$axios.get(`/custom-activity/${this.itemID}`);

        if (data) {
          this.obj = data.data;
        }
      }
    }
  },
  async created() {
    await this.fetchItemById();
    this.step = 1;
  }
};
</script>